import {
    GET,
    db
} from '@/firebase.js'

import Avatar from '@/components/Avatar/Avatar.vue'
import Skeleton from '@/components/Skeleton/Skeleton.vue'

export default {
    name: "Start",
    components: {
        Avatar,
        Skeleton
    },
    data: function () {
        return {
            events: [],
            eventObj: {
                'armada': {
                    colors: {
                        primary: '#546399'
                    },
                    cover: 'armada-bg.svg'
                },
                'finance': {
                    colors: {
                        primary: '#447563'
                    },
                    cover: 'banks-bg.svg'
                },
                'fashion': {
                    colors: {
                        primary: '#A83E57'
                    },
                    cover: 'fashion-bg.svg'
                },
            },
            production: (process.env.NODE_ENV === 'production'),
            coldProduction: (window.location.hostname === 'gokind.app'),
            loaded: false,
        }
    },
    methods: {
        getEvents: function () {
            console.log('Fetching events')
            let col = db.collection('events')
            col = col.where('show', '==', true)
            col = col.orderBy('created', 'desc')
            GET(col).then((events) => {
                events.forEach(async event => {
                    let orgs = (event.data().popular_orgs) ? await this.getPopularOrgs(event) : null
                    this.events.push({
                        name: event.data().name,
                        eid: event.data().eid,
                        orgs: orgs,
                        colors: this.eventObj[event.data().eid].colors,
                        cover: this.eventObj[event.data().eid].cover,
                        desc: event.data().desc,
                        released: event.data().released
                    })
                    this.events.sort(function (x, y) {
                        return y.released - x.released
                    });
                })
            })
        },
        getPopularOrgs: function (event) {
            return new Promise(resolve => {
                let col = db.collection('orgs').where('events.' + event.id, '==', true)
                col = col.where('__name__', 'in', event.data().popular_orgs)
                col = col.limit(6)
                GET(col, true).then(orgs => {
                    let orgsArr = []
                    orgs.forEach(org => {
                        orgsArr.push({
                            name: org.data().name,
                            logo: org.data().logo,
                            oid: org.data().oid,
                            member: org.data().member,
                        })
                    })
                    resolve(orgsArr)
                })
            })
        },
    },
    created: function () {
        if (this.events.length === 0) this.getEvents()
        setTimeout(() => {
            this.loaded = true
        }, 200)
    }
};